import { Auth } from 'aws-amplify';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

export function useAuthLogout(): () => void {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return async () => {
    await Auth.signOut();
    queryClient.clear();
    navigate('/');
  };
}
