import * as React from 'react';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import concatClasses from '../../../util/concatClasses';

import styles from './Dialog.m.css';

export const Dialog: React.FC<{
  title?: string;
  message: JSX.Element | string | undefined;
  buttons: JSX.Element[];
  fixedButtonWidth?: boolean;
  large?: boolean;
}> = (props) => {
  const { title, buttons, message, fixedButtonWidth = true, large } = props;

  const [containerHeight, setContainerHeight] = React.useState<
    number | undefined
  >(undefined);

  const dialogRef = useRef<HTMLDivElement>(null);

  // We need a way to stop a user scrolling during the dialog
  // overlay being shown
  useEffect(() => {
    if (message && dialogRef && dialogRef.current) {
      dialogRef.current.scrollIntoView();
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'initial';
    }

    return () => {
      document.body.style.overflowY = 'initial';
    };
  }, [message]);

  useEffect(() => {
    const listener = () => {
      setContainerHeight(window.innerHeight - 10);
    };
    window.addEventListener('resize', listener);
    listener();
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return message
    ? createPortal(
        <div
          data-test="dialog"
          ref={dialogRef}
          className={styles.dialogContainer}
        >
          <div
            className={concatClasses(styles.dialog, large && styles.large)}
            style={{ maxHeight: containerHeight }}
          >
            {title ? <h2>{title}</h2> : null}
            <span className={styles.message}>{message}</span>
            <div className={styles.buttons}>
              {buttons.map((button) => (
                <div
                  key={button.key + '-container'}
                  className={
                    fixedButtonWidth
                      ? styles.buttonContainer
                      : styles.buttonContainerFullWidth
                  }
                >
                  {button}
                </div>
              ))}
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
};
