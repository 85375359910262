import { PermissionsDto } from '@dentreality/hyper-portal-dtos';

import { useAPIQuery2 } from './useAPIQuery';

export const useGetPermissions = () => {
  return useAPIQuery2<PermissionsDto, Error>({
    path: () => `permissions`,
    options: {
      staleTime: Infinity,
      refetchOnMount: true,
    },
  });
};
