import { VenueDocumentDto } from '@dentreality/hyper-portal-dtos';

import { useAPIQuery2 } from './useAPIQuery';

export const useGetVenueDocument = (orgSlug?: string, venueSlug?: string) => {
  return useAPIQuery2<VenueDocumentDto[], Error>({
    path: () => `organisations/${orgSlug}/venues/${venueSlug}/documents`,
    options: {
      enabled: orgSlug != null && venueSlug != null,
    },
  });
};
