import {
  VenueDocumentCreateDto,
  VenueDocumentDto,
} from '@dentreality/hyper-portal-dtos';

import { useAPIMutation2 } from './useAPIQuery';

export const useCreateDocument = (orgSlug?: string, venueSlug?: string) => {
  const mutation = useAPIMutation2<
    VenueDocumentDto,
    Error,
    VenueDocumentCreateDto
  >({
    path: () => `organisations/${orgSlug}/venues/${venueSlug}/documents`,
  });

  if (!orgSlug || !venueSlug) {
    return null;
  }
  return mutation;
};
