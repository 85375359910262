import React from 'react';

const SolidLogo: React.FC<{ color?: `#${string}` }> = ({
  color = '#FFFFFF',
}) => {
  return (
    <svg
      viewBox="0 0 1200 1475"
      fill="none"
      style={{ maxWidth: '100%', maxHeight: '100%' }}
    >
      <path
        d="M750.435 0H607.826C582.391 0 559.239 14.8077 548.587 37.9992L5.97833 1218.04C-2.49993 1236.55 -1.95645 1258.11 7.71746 1276.08L85.9783 1423.06C102.935 1454.97 136.196 1475 172.283 1475H313.804C339.348 1475 362.5 1460.08 373.152 1436.89L915.652 250.098C924.239 231.262 923.478 209.485 913.478 191.411L836.087 50.7382C818.913 19.3807 786.087 0 750.435 0Z"
        fill={color}
      />
      <path
        d="M1028.8 667.762H886.196C860.761 667.762 837.609 682.679 826.957 705.761L591.957 1218.04C583.478 1236.55 584.131 1258 593.696 1276.08L671.957 1423.06C688.913 1454.97 722.174 1475 758.261 1475H899.674C925.218 1475 948.478 1459.97 959.022 1436.67L1194.02 917.751C1202.5 899.024 1201.63 877.248 1191.74 859.282L1114.35 718.5C1097.28 687.252 1064.46 667.762 1028.8 667.762Z"
        fill={color}
      />
    </svg>
  );
};
export default SolidLogo;
