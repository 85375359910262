import React, { createContext, useContext, useEffect } from 'react';

import {
  Action as PermissionAction,
  hasPermission,
} from '@dentreality/hyper-permissions';

import LoadingLogo from '../components/common/hyper-logo/LoadingLogo';
import { useAuthUser } from '../hooks/useAuthUser';
import { useGetPermissions } from '../hooks/useGetPermissions';

const PermissionContext = createContext({});

type Props = React.PropsWithChildren<unknown>;

export function PermissionProvider({ children }: Props) {
  const user = useAuthUser();

  const { data: permissions, refetch, isFetched } = useGetPermissions();

  useEffect(() => {
    if (user.user.email) {
      refetch();
    }
  }, [user.user.email, refetch]);

  return (
    <PermissionContext.Provider value={permissions?.permissions ?? {}}>
      {isFetched ? children : <LoadingLogo fullScreen />}
    </PermissionContext.Provider>
  );
}

export { Action as PermissionAction } from '@dentreality/hyper-permissions';

export function usePermissionCheck(
  permission: string,
  action: PermissionAction,
) {
  const permissions = useContext(PermissionContext);
  return hasPermission(permissions, permission, action);
}

export function useHasPermission(permissions?: [string, PermissionAction][]) {
  const perms = useContext(PermissionContext);
  const decisions = permissions?.reduce<Record<string, boolean>>(
    (aggr, [perm, action]) => ({
      ...aggr,
      [`${perm}.action.${action}`]: hasPermission(perms, perm, action),
    }),
    {},
  );

  return {
    permissions: perms,
    decisions,
    checkPermission: (permission: string, actions: PermissionAction) =>
      hasPermission(perms, permission, actions),
  };
}
