import React from 'react';

const GradientLogo: React.FC = () => {
  return (
    <svg
      viewBox="0 0 1200 1455"
      fill="none"
      style={{ maxWidth: '100%', maxHeight: '100%' }}
    >
      <g clipPath="url(#clip0_104_60)">
        <path
          d="M744.525 0.320412L603.204 -2.52996e-05C578.209 -0.106838 555.457 14.4197 544.882 37.1707L5.98174 1199.5C-2.45693 1217.66 -1.92284 1238.81 7.4772 1256.44L84.6002 1401.91C101.264 1433.32 133.844 1452.97 169.307 1453.08L312.444 1453.4C335.731 1453.5 356.988 1439.94 366.815 1418.68L906.89 247.164C915.435 228.686 914.687 207.323 904.86 189.592L828.592 50.3087C811.821 19.5467 779.562 0.427225 744.525 0.320412Z"
          fill="url(#paint0_radial_104_60)"
        />
        <path
          opacity="0.2"
          d="M9.18632 1259.85L84.4934 1402.02C101.157 1433.42 133.737 1453.08 169.201 1453.18L312.124 1453.5C329.535 1453.5 340.644 1435.03 332.419 1419.65L256.792 1279.4C240.021 1248.32 207.655 1228.98 172.405 1228.88L28.0932 1228.56C12.0704 1228.45 1.70902 1245.65 9.18632 1259.85Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M744.507 0.321023L603.186 0.000585052C578.191 -0.106227 555.438 14.4203 544.863 37.1713L5.96343 1199.51C-2.47524 1217.66 -1.94115 1238.81 7.45889 1256.44L81.591 1396.36C93.9819 1419.65 127.63 1418.9 138.846 1395.08L668.559 272.586C684.475 238.833 718.443 217.364 755.723 217.471L884.226 217.791C900.462 217.791 910.824 200.381 903.026 186.175L828.573 50.3093C811.803 19.5473 779.544 0.427835 744.507 0.321023Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M744.525 0.320438L602.884 0C585.045 0 574.15 19.4399 583.336 34.6073L666.548 171.22C683.959 199.739 714.937 217.257 748.371 217.363L884.137 217.684C900.374 217.684 910.735 200.273 902.937 186.067L828.592 50.3087C811.821 19.5467 779.562 0.42725 744.525 0.320438Z"
          fill="white"
        />
        <path
          d="M1031.87 636.816L890.333 636.496C865.444 636.389 842.799 650.809 832.117 673.346L584.084 1201C575.538 1219.26 575.966 1240.41 585.473 1258.25L662.489 1403.52C679.152 1434.92 711.732 1454.57 747.196 1454.68L888.944 1455C913.192 1455.11 935.197 1441.01 945.451 1419.11L1194.12 883.767C1202.67 865.288 1202.03 843.819 1192.2 825.981L1116.04 686.804C1099.16 656.042 1066.9 636.816 1031.87 636.816Z"
          fill="url(#paint1_radial_104_60)"
        />
        <path
          opacity="0.2"
          d="M587.182 1261.35L662.489 1403.52C679.152 1434.92 711.732 1454.57 747.196 1454.68L887.876 1455C906.249 1455 918.106 1435.35 909.346 1419.22L834.894 1280.9C818.123 1249.81 785.757 1230.48 750.507 1230.37L606.195 1230.05C589.959 1229.95 579.598 1247.04 587.182 1261.35Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M1031.87 636.816L890.333 636.496C865.444 636.389 842.799 650.809 832.117 673.346L584.084 1201C575.538 1219.26 575.966 1240.41 585.473 1258.25L659.925 1398.82C672.209 1421.99 705.537 1421.46 717.073 1397.86L955.599 907.906C971.729 874.794 1005.38 853.752 1042.23 853.859L1171.48 854.18C1187.72 854.18 1198.08 836.769 1190.28 822.563L1115.93 686.698C1099.16 656.042 1066.9 636.816 1031.87 636.816Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M1031.87 636.816L890.333 636.496C872.494 636.496 861.492 656.042 870.785 671.21L953.997 807.716C971.408 836.235 1002.39 853.752 1035.82 853.859L1171.59 854.18C1187.82 854.18 1198.18 836.769 1190.39 822.563L1116.04 686.698C1099.16 656.042 1066.9 636.816 1031.87 636.816Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_104_60"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(909.453 -176.975) rotate(116.793) scale(1824.67 1982.71)"
        >
          <stop stopColor="#A9BEF8" />
          <stop offset="0.37488" stopColor="#224DCF" />
          <stop offset="1" stopColor="#3B0E6E" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_104_60"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1197.8 536.829) rotate(121.43) scale(1075 1291.7)"
        >
          <stop stopColor="#A9BEF8" />
          <stop offset="0.37488" stopColor="#224DCF" />
          <stop offset="1" stopColor="#3B0E6E" />
        </radialGradient>
        <clipPath id="clip0_104_60">
          <rect width="1200" height="1455" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GradientLogo;
