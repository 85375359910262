import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { useAuthToken } from './useAuthToken';

const apiPath = '/api/v1/';

export class APIError extends Error {
  public readonly statusCode: Response['status'];
  constructor(public response: Response) {
    super();
    this.statusCode = response.status;
    this.parseBody();
  }

  private async parseBody() {
    const contentType = this.response.headers.get('content-type');
    if (contentType?.startsWith('application/json')) {
      this.message = await this.response.json();
    } else {
      this.message = await this.response.text();
    }
  }
}

function authFetch(
  path: string,
  options: RequestInit,
  token: string | undefined,
  formData?: boolean,
) {
  let headers = {};

  if (!formData) {
    headers = { 'Content-Type': 'application/json' };
  }

  return fetch(path, {
    headers: {
      ...headers,
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    ...options,
  }).then(async function handleResponse(res: Response) {
    if (res.status >= 200 && res.status < 400) {
      if (res.status === 204) {
        return;
      } else {
        const contentType = res.headers.get('content-type');
        if (!contentType) {
          console.error('HTTP Error, no content type set', res.status);
          throw res.status;
        }
        if (contentType.startsWith('application/json')) {
          return res.json();
        } else if (contentType.startsWith('application/zip')) {
          return res.arrayBuffer();
        }
      }
    } else {
      throw new APIError(res);
    }
  });
}

type QueryOptions<TData, TError> =
  | Omit<
      UseQueryOptions<TData, TError, TData, QueryKey>,
      'queryKey' | 'queryFn'
    >
  | undefined;

export const useAPIQuery = <TData, TError, TVariables = undefined>(query: {
  key: string;
  variables?: TVariables;
  path: (variables: TVariables) => string;
  options?: QueryOptions<TData, TError>;
}): UseQueryResult<TData, TError> => {
  const { key, variables, path, options } = query;
  const auth = useAuthToken();

  return useQuery<TData, TError>(
    variables ? [key, variables] : key,
    async ({ queryKey }) => {
      // try {
      //   if (isAuthenticated) {
      //     token = await getToken();
      //   }
      // } catch (error) {
      //   throw error;
      // }

      const fetchPath = `${apiPath}${path(queryKey[1] as TVariables)}`;
      const token = await auth.getToken();
      return authFetch(fetchPath, { method: 'GET' }, token);
    },
    { ...options },
  );
};

type MutationOptions<TData, TError, TVariables, TContext> =
  | Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>
  | undefined;

export const useAPIMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = undefined,
  TContext = unknown,
>(query: {
  path: (variables: TVariables) => string;
  method?: 'PUT' | 'POST' | 'DELETE';
  options?: MutationOptions<TData, TError, TVariables, TContext>;
}) => {
  const { path, method = 'POST', options } = query;
  const auth = useAuthToken();

  return useMutation<TData, TError, TVariables, TContext>(
    async (variables) => {
      let fetchOptions = {};

      const body = (variables as any).body;

      const hasBody = variables && body;

      let isFormData = false;

      if (hasBody) {
        let fetchOptionsBody;

        if (body instanceof FormData) {
          fetchOptionsBody = body;
          isFormData = true;
        } else if (typeof body === 'object') {
          try {
            fetchOptionsBody = JSON.stringify(body);
          } catch {}
        }

        if (!fetchOptionsBody) {
          throw new Error('Body must be JSON object or FormData');
        }

        fetchOptions = { body: fetchOptionsBody };
      }

      const fetchPath = `${apiPath}${path(variables)}`;
      const token = await auth.getToken();
      return authFetch(
        fetchPath,
        { method, ...fetchOptions },
        token,
        isFormData,
      );
    },
    {
      ...options,
    },
  );
};

export const useAPIQuery2 = <TData, TError>(query: {
  path: () => string;
  options?: QueryOptions<TData, TError>;
}): UseQueryResult<TData, TError> => {
  const { path, options } = query;
  const auth = useAuthToken();
  return useQuery<TData, TError>(
    path(),
    async () => {
      const fetchPath = `${apiPath}${path()}`;
      const token = await auth.getToken();
      return authFetch(fetchPath, { method: 'GET' }, token);
    },
    { ...options },
  );
};

export type MutateOptions<TData> = {
  enabled?: boolean;
  onSuccess?: (data: TData) => void;
  onError?: () => void;
};

export const useAPIMutation2 = <
  TData = unknown,
  TError = unknown,
  TBody = undefined,
  TContext = unknown,
>(query: {
  path: (variables: TBody) => string;
  method?: 'PUT' | 'POST' | 'PATCH' | 'DELETE';
  options?: MutationOptions<TData, TError, TBody, TContext>;
}) => {
  const { path, method = 'POST', options } = query;
  const auth = useAuthToken();

  return useMutation<TData, TError, TBody, TContext>(
    async (body) => {
      let fetchOptions = {};

      let isFormData = false;

      if (body) {
        let fetchOptionsBody;

        if (body instanceof FormData) {
          fetchOptionsBody = body;
          isFormData = true;
        } else if (typeof body === 'object') {
          try {
            fetchOptionsBody = JSON.stringify(body);
          } catch {}
        }

        if (!fetchOptionsBody) {
          throw new Error('Body must be JSON object or FormData');
        }

        fetchOptions = { body: fetchOptionsBody };
      }

      const fetchPath = `${apiPath}${path(body)}`;
      const token = await auth.getToken();
      return authFetch(
        fetchPath,
        { method, ...fetchOptions },
        token,
        isFormData,
      );
    },
    {
      ...options,
    },
  );
};
