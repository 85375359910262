// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--YdNfE {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
}

.logo--ZsrR0 {
  position: absolute;
  overflow: hidden;
  transition: clip-path 1.5s ease-in-out;
}

.logo-fill--bjVj5 {
  animation: logo-anim--DTMJe 1.5s linear infinite;
}

@keyframes logo-anim--DTMJe {
  0% {
    opacity: 1;
    clip-path: inset(100% 0 0 0);
  }
  75% {
    opacity: 1;
    clip-path: inset(0 0 0 0);
  }
  100% {
    opacity: 0;
    clip-path: inset(0 0 0 0);
  }
}

.fullscreen--lK00Q {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3083e7;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/hyper-logo/LoadingLogo.m.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;EACE,gDAAyC;AAC3C;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,yBAAyB;EAC3B;EACA;IACE,UAAU;IACV,yBAAyB;EAC3B;AACF;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;AAC3B","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  margin: auto;\n}\n\n.logo {\n  position: absolute;\n  overflow: hidden;\n  transition: clip-path 1.5s ease-in-out;\n}\n\n.logo-fill {\n  animation: logo-anim 1.5s linear infinite;\n}\n\n@keyframes logo-anim {\n  0% {\n    opacity: 1;\n    clip-path: inset(100% 0 0 0);\n  }\n  75% {\n    opacity: 1;\n    clip-path: inset(0 0 0 0);\n  }\n  100% {\n    opacity: 0;\n    clip-path: inset(0 0 0 0);\n  }\n}\n\n.fullscreen {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #3083e7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container--YdNfE`,
	"logo": `logo--ZsrR0`,
	"logo-fill": `logo-fill--bjVj5`,
	"logo-anim": `logo-anim--DTMJe`,
	"fullscreen": `fullscreen--lK00Q`
};
export default ___CSS_LOADER_EXPORT___;
