// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialogContainer--PVNEh {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--overlay-color);
  z-index: 2000;

  justify-content: center;
  align-items: center;
}

.dialog--eKZil {
  min-width: 33%;
  max-width: 80%;
  background: white;
  color: black;
  flex-direction: column;
  text-align: center;
  border-radius: 8px;
  z-index: 1;
  display: flex;
  position: relative;
  /* height: 70%; */
  width: calc(var(--grid-size) * 55);
  padding: calc(var(--grid-size) * 4);
  overflow-y: auto;
}

.dialog--eKZil.large--YNomc {
  width: calc(var(--grid-size) * 110);
}

.dialog--eKZil h2 {
  margin: 0 0 calc(var(--grid-size) * 2) 0;
}

.buttons--kt_KX {
  display: flex;
  justify-content: center;
  margin-top: calc(var(--grid-size) * 2);
}

.buttonContainer--x0RgV {
  margin: calc(var(--grid-size) * 2);
  margin-bottom: 0;
  width: calc(var(--grid-size) * 14);
}

.buttonContainerFullWidth--syeTR {
  margin: calc(var(--grid-size) * 3);
  margin-bottom: 0;
  width: 100%;
}

.message--LsoVC {
  padding-bottom: calc(var(--grid-size) * 2);
  white-space: pre-line;
  width: 100%;
  font-size: 14px;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/dialog/Dialog.m.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;;EAEb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,iBAAiB;EACjB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,kCAAkC;EAClC,mCAAmC;EACnC,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,sCAAsC;AACxC;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,0CAA0C;EAC1C,qBAAqB;EACrB,WAAW;EACX,eAAe;EACf,OAAO;AACT","sourcesContent":["@import '../../variables.css';\n\n.dialogContainer {\n  display: flex;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: var(--overlay-color);\n  z-index: 2000;\n\n  justify-content: center;\n  align-items: center;\n}\n\n.dialog {\n  min-width: 33%;\n  max-width: 80%;\n  background: white;\n  color: black;\n  flex-direction: column;\n  text-align: center;\n  border-radius: 8px;\n  z-index: 1;\n  display: flex;\n  position: relative;\n  /* height: 70%; */\n  width: calc(var(--grid-size) * 55);\n  padding: calc(var(--grid-size) * 4);\n  overflow-y: auto;\n}\n\n.dialog.large {\n  width: calc(var(--grid-size) * 110);\n}\n\n.dialog h2 {\n  margin: 0 0 calc(var(--grid-size) * 2) 0;\n}\n\n.buttons {\n  display: flex;\n  justify-content: center;\n  margin-top: calc(var(--grid-size) * 2);\n}\n\n.buttonContainer {\n  margin: calc(var(--grid-size) * 2);\n  margin-bottom: 0;\n  width: calc(var(--grid-size) * 14);\n}\n\n.buttonContainerFullWidth {\n  margin: calc(var(--grid-size) * 3);\n  margin-bottom: 0;\n  width: 100%;\n}\n\n.message {\n  padding-bottom: calc(var(--grid-size) * 2);\n  white-space: pre-line;\n  width: 100%;\n  font-size: 14px;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": `dialogContainer--PVNEh`,
	"dialog": `dialog--eKZil`,
	"large": `large--YNomc`,
	"buttons": `buttons--kt_KX`,
	"buttonContainer": `buttonContainer--x0RgV`,
	"buttonContainerFullWidth": `buttonContainerFullWidth--syeTR`,
	"message": `message--LsoVC`
};
export default ___CSS_LOADER_EXPORT___;
