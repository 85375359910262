import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  VenueDocumentCreateDto,
  VenueDocumentDto,
} from '@dentreality/hyper-portal-dtos';

import {
  PermissionAction,
  usePermissionCheck,
} from '../../context/permissions';
import { useCreateDocument } from '../../hooks/useCreateDocument';
import { useGetOrganisation } from '../../hooks/useGetOrganisation';
import { useGetVenueDocument } from '../../hooks/useGetVenueDocument';
import { ConfirmationDialogContext } from '../app/AppMessages';
import MainLayout from '../app/layout/MainLayout';
import { ConfirmationDialog } from '../common/confirmation-dialog/ConfirmationDialog';
import LoadingLogo from '../common/hyper-logo/LoadingLogo';

const Organisation = () => {
  const { orgSlug } = useParams();
  const navigate = useNavigate();
  const hasReadPermission = usePermissionCheck(
    `organisations/${orgSlug}`,
    PermissionAction.READ,
  );

  useEffect(() => {
    if (!hasReadPermission) {
      navigate('/');
    }
  }, [hasReadPermission, navigate]);

  const { data: orgDto, isLoading, error } = useGetOrganisation(orgSlug);

  if (isLoading) {
    return <LoadingLogo fullScreen />;
  } else if (error && (error.statusCode === 404 || error?.statusCode === 403)) {
    return (
      <ConfirmationDialog
        messages={{
          title: 'Organisation not found',
          message: 'The organisation you requested was not found.',
          confirmMessage: 'OK',
        }}
        onConfirm={() => {
          navigate(`/`);
        }}
      />
    );
  } else if (orgDto == null) {
    return (
      <ConfirmationDialog
        messages={{
          title: 'Missing organisation data',
          message: 'There was a problem loading the organisation data.',
          confirmMessage: 'OK',
        }}
        onConfirm={() => {
          navigate(`/`);
        }}
      />
    );
  } else {
    return <Outlet />;
  }
};

const VenuePage = () => {
  const { orgSlug, venueSlug } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const seq = searchParams.get('seq');

  const [venueDocument, setVenueDocument] = useState<
    VenueDocumentDto | undefined
  >(undefined);

  const createVersion = useCreateDocument(orgSlug, venueSlug);

  const { data: versions, refetch: versionRefetch } = useGetVenueDocument(
    orgSlug,
    venueSlug,
  );

  useEffect(() => {
    if (!versions || !createVersion) {
      return;
    }

    if (versions.length === 0) {
      // There is no version and underlying map data, so we create one
      createVersion.mutate(
        {
          slug: 'new-document',
          name: 'New Document',
          description: null,
        } as VenueDocumentCreateDto,
        {
          // Once it's been created let's refetch it
          onSuccess: () => {
            versionRefetch();
          },
        },
      );
    } else {
      // We get the 'default' version
      setVenueDocument(versions[0]);
    }
  }, [versions]);

  useEffect(() => {
    if (venueDocument) {
      const baseRoute = `/${orgSlug}/venue/${venueSlug}/document/${venueDocument.slug}`;
      const seqQuery = seq ? `?seq=${seq}` : '';
      navigate({ pathname: baseRoute, search: seqQuery }, { replace: true });
    }
  }, [venueDocument]);

  return <LoadingLogo />;
};

const SupportAssetUpload = lazy(
  () => import('../pages/asset/SupportAssetUpload'),
);
const AssetDownload = lazy(() => import('../pages/asset/AssetDownload'));
const AcceptInvitePage = lazy(() => import('../pages/access/AcceptInvitePage'));
const OrganisationListPage = lazy(
  () => import('../pages/organisations/OrganisationsPage'),
);
const VenueListPage = lazy(() => import('../pages/venues/VenuesPage'));
const AccessPage = lazy(() => import('../pages/access/AccessPage'));
const AssetsPage = lazy(
  () => import('../pages/organisations/AssetsPage/AssetsPage'),
);
const ApiKeyPage = lazy(
  () => import('../pages/organisations/ApiKeyPage/ApiKeyPage'),
);
const MgmntPages = lazy(() => import('../pages/venues/MgmntPages'));
const EditorPage = lazy(() => import('../pages/VersionPage'));

export default function AppNavigation() {
  const location = useLocation();
  const setConfirmationDialog = useContext(ConfirmationDialogContext);

  // Ensure dialog boxes are closed on navigation
  useEffect(() => {
    setConfirmationDialog(undefined);
  }, [location]);

  return (
    <Suspense fallback={<LoadingLogo />}>
      <Routes>
        <Route
          path="support-asset/:ticketId"
          element={<SupportAssetUpload />}
        />
        <Route path="" Component={MainLayout}>
          <Route path="asset/:assetId" Component={AssetDownload} />
          <Route path="invite/:orgId" Component={AcceptInvitePage} />
          <Route path="" Component={OrganisationListPage} />
          <Route path="/access" Component={AccessPage} />
          <Route path=":orgSlug" Component={Organisation}>
            <Route path="" element={<Navigate to="venues" replace />} />
            <Route path="venues" Component={VenueListPage} />
            <Route path="access" Component={AccessPage} />
            <Route path="assets" Component={AssetsPage} />
            <Route path="api-keys" Component={ApiKeyPage} />
            <Route path="venue/:venueSlug">
              <Route path="" Component={VenuePage} />
              <Route path="management/:tab" Component={MgmntPages}></Route>
            </Route>
          </Route>
        </Route>
        <Route
          path=":orgSlug/venue/:venueSlug/document/:documentSlug"
          Component={EditorPage}
        />
        <Route
          path="*"
          element={
            <MainLayout>
              <h1>Not found</h1>
              <p>The page could not be found.</p>
            </MainLayout>
          }
        />
      </Routes>
    </Suspense>
  );
}
