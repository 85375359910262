import { Notifications, notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import * as React from 'react';
import { createContext, useState } from 'react';

import { SnackbarProps } from '../common/snackbar/Snackbar';

//TODO: Remove all uses of SnackBar context in favour of `notifications.show()`
// https://linear.app/hyperar/issue/PLT-1373/remove-snackbar-context-in-favour-of-mantinenotifications
export const SnackbarContext = createContext<(props: SnackbarProps) => void>(
  () => undefined,
);
export const ConfirmationDialogContext = createContext<
  (value: JSX.Element | undefined) => void
>(() => undefined);

export const AppMessages: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (props) => {
  const { children } = props;

  const [confirmationDialog, setConfirmationDialog] = useState<
    JSX.Element | undefined
  >();

  const handleMessage = React.useCallback((props: SnackbarProps) => {
    if (props.message)
      notifications.show({ message: props.message, autoClose: props.duration });
  }, []);

  return (
    <>
      <SnackbarContext.Provider value={handleMessage}>
        <ConfirmationDialogContext.Provider value={setConfirmationDialog}>
          {confirmationDialog}
          {children}
        </ConfirmationDialogContext.Provider>
      </SnackbarContext.Provider>
      <Notifications />
    </>
  );
};
