// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fallback--uauCr {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primary-to-tint-gradient);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

* {
  font-family: var(--mantine-font-family);
  font-variant-numeric: tabular-nums;
}

/* This is a fix because zindex prop doesn't actually apply the style */
.navProgress--gjVmY {
  z-index: var(--nprogress-z-index);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/app/App.m.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,2CAA2C;EAC3C,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,uCAAuC;EACvC,kCAAkC;AACpC;;AAEA,uEAAuE;AACvE;EACE,iCAAiC;AACnC","sourcesContent":["@import '../variables.css';\n\n.fallback {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: var(--primary-to-tint-gradient);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n\n* {\n  font-family: var(--mantine-font-family);\n  font-variant-numeric: tabular-nums;\n}\n\n/* This is a fix because zindex prop doesn't actually apply the style */\n.navProgress {\n  z-index: var(--nprogress-z-index);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fallback": `fallback--uauCr`,
	"navProgress": `navProgress--gjVmY`
};
export default ___CSS_LOADER_EXPORT___;
