import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';

import HyperLogo from './HyperLogo';
import styles from './LoadingLogo.m.css';

interface LoadingLogoProps {
  progress?: number;
  size?: number;
  fullScreen?: boolean;
}

const LoadingLogo: React.FC<LoadingLogoProps> = ({
  size = 128,
  progress,
  fullScreen,
}) => {
  const clipPath = useMemo(() => {
    if (!progress) return null;
    return { clipPath: `inset(${100 - progress}% 0 0 0)` };
  }, [progress]);

  const loader = (
    <div className={styles.container} style={{ height: size, width: size }}>
      <div className={styles.logo} style={{ width: size, height: size }}>
        <HyperLogo style={{ width: size, height: size }} variant="glass" />
      </div>
      <div
        className={[styles.logo, clipPath === null && styles['logo-fill']]
          .filter((a) => a)
          .join(' ')}
        style={{
          width: size,
          height: size,
          ...(clipPath !== null && clipPath),
        }}
      >
        <HyperLogo style={{ width: size, height: size }} variant="gradient" />
      </div>
    </div>
  );
  if (fullScreen)
    return createPortal(
      <div key="fullscreen-loader" className={styles.fullscreen}>
        {loader}
      </div>,
      document.body,
    );
  return loader;
};

export default LoadingLogo;
