import './App.m.css';

import { useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { PermissionProvider } from '../../context/permissions';
import { SSEProvider } from '../../context/SSE';
import * as logo from '../../images/logo.png';
import AppNavigation from '../navigation/AppNavigation';
import styles from '../navigation/AppNavigation.m.css';

import { AppMessages } from './AppMessages';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

function App() {
  const { user } = useAuthenticator((context) => [context.user]);
  return (
    <Router>
      <RecoilRoot key={user?.username}>
        <AppMessages>
          <QueryClientProvider client={queryClient}>
            <PermissionProvider>
              <SSEProvider>
                <AppNavigation />
              </SSEProvider>
            </PermissionProvider>
          </QueryClientProvider>
        </AppMessages>
      </RecoilRoot>
    </Router>
  );
}

function SignInHeader() {
  return (
    <>
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={logo} alt="Hyper Logo" />
      </div>
      <div>
        <h1 className={styles.title}>Login</h1>
      </div>
    </>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  loginMechanisms: ['email'],
  // socialProviders: ['google', 'apple'],
  // formFields: formFields,
  components: {
    // Header,
    SignIn: {
      Header: SignInHeader,
      // Footer: SignInFooter,
    },
    // Footer,
  },
});
