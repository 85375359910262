import {
  EventSourcePolyfill as EventSource,
  type Event,
  type EventListenerOrEventListenerObject,
} from 'event-source-polyfill';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// We use the polyfill here because the native EventSource does not support authorization headers
import {
  MessageEvent as PortalMessageEvent,
  messageEventSchema,
} from '@dentreality/hyper-portal-dtos';

import { useAuthToken2 } from '../hooks/useAuthToken';

/*
 * We use context here so that we can have a single EventSource for all
 * components.
 * We store the listeners from lower in the component tree and only subscribe to
 * server events for which we have listeners.
 * This way we can avoid creating a new EventSource for every component and
 * every event type, and results in a single connection to the server.
 */

export type EventType = PortalMessageEvent['type'];
type EventData<T extends EventType> = Extract<PortalMessageEvent, { type: T }>;
type Callback<T extends EventType> = (data: EventData<T>) => void;

type Ctx = {
  addListener: <T extends EventType>(event: T, callback: Callback<T>) => void;
  removeListener: <T extends EventType>(
    event: T,
    callback: Callback<T>,
  ) => void;
};
const SSEContext = createContext<Ctx>({
  addListener: () => {
    throw new Error('SSEContext not instantiated');
  },
  removeListener: () => {
    throw new Error('SSEContext not instantiated');
  },
});

export function SSEProvider({ children }: PropsWithChildren<unknown>) {
  const getToken = useAuthToken2();

  const [listeners, setListeners] = useState<
    Record<string, Callback<EventType>[]>
  >({});

  const addListener: Ctx['addListener'] = useCallback(
    function addListener<T extends EventType>(event: T, callback: Callback<T>) {
      setListeners((listeners) => {
        const newListeners = { ...listeners };
        if (newListeners[event]) {
          newListeners[event].push(callback as unknown as Callback<EventType>); // This is gross -- idk why TS can't figure out that Callback<T>
        } else {
          newListeners[event] = [callback as unknown as Callback<EventType>];
        }
        return newListeners;
      });
    },
    [setListeners],
  );

  const removeListener: Ctx['removeListener'] = useCallback(
    function removeListener<T extends EventType>(
      event: T,
      callback: Callback<T>,
    ) {
      setListeners((listeners) => {
        const newListeners = { ...listeners };
        if (newListeners[event]) {
          newListeners[event] = newListeners[event].filter(
            (cb) => cb !== (callback as unknown as Callback<EventType>),
          );
        }

        if (newListeners[event].length === 0) {
          delete newListeners[event];
        }
        return newListeners;
      });
    },
    [setListeners],
  );

  // Stringify the params here so that the shallow equality for the below effect
  // will not retrigger the effect if the listeners object changes, but the keys
  // remain the same
  const params = useMemo(() => {
    const keys = Object.keys(listeners);
    // We sort the keys so that the order of the keys doesn't affect the URL
    // query params for shallow comparison
    keys.sort();
    return new URLSearchParams(keys.map((key) => ['event', key])).toString();
  }, [listeners]);

  // Set up the EventSource
  const [eventSource, setEventSource] = useState<EventSource | null>(null);
  useEffect(() => {
    if (params.length === 0) return;

    async function setupEventSource() {
      const eventSource = new EventSource(`/api/v1/events?${params}`, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
      });

      setEventSource(eventSource);
      return eventSource;
    }

    const eventSource = setupEventSource();

    return () => {
      eventSource.then((es) => es.close());
      setEventSource(null);
    };
  }, [params, getToken]);

  // Register the listeners on the event source
  // Having this separate to the above means that we don't re-send the
  // EventSource GET request every time a listener is added or removed, only
  // when the set listener keys changes
  useEffect(() => {
    // Can't add callbacks to an EventSource that doesn't exist
    if (!eventSource) return;

    // Create a list of the callbacks we add to event source so we can remove
    // them in teardown
    const callbacks: [string, EventListenerOrEventListenerObject][] =
      Object.entries(listeners).map(([type, callbacks]) => {
        // For every event type, create a single callback that parses the event
        // and then calls all the callbacks for that event type
        function sseCallback(e: Event) {
          try {
            if (!('data' in e && typeof e.data === 'string')) {
              throw new Error('Message event data is not a string');
            }

            const event = messageEventSchema.parse({
              type: e.type,
              data: JSON.parse(e.data),
            });

            callbacks.forEach((cb) => cb(event));
          } catch (e) {
            console.error(e);
          }
        }

        // Add the callback to the event source
        eventSource.addEventListener(type, sseCallback);
        return [type, sseCallback];
      });

    return () => {
      // For each callback we added, remove it
      callbacks.forEach(([type, cb]) =>
        eventSource.removeEventListener(type, cb),
      );
    };
  }, [eventSource, listeners]);

  const value = useMemo(
    () => ({ addListener, removeListener }),
    [addListener, removeListener],
  );

  return <SSEContext.Provider value={value}>{children}</SSEContext.Provider>;
}

export function useServerEvents<T extends EventType>(
  event: T,
  callback: Callback<T>,
) {
  const { addListener, removeListener } = useContext(SSEContext);

  useEffect(() => {
    addListener(event, callback);
    return () => removeListener(event, callback);
  }, [event, callback, addListener, removeListener]);
}
