import { OrganisationDto } from '@dentreality/hyper-portal-dtos';

import { APIError, useAPIQuery2 } from './useAPIQuery';

export const useGetOrganisation = (orgSlug: string | undefined) => {
  return useAPIQuery2<OrganisationDto, APIError>({
    path: () => `organisations/${orgSlug}`,
    options: {
      staleTime: Infinity,
      refetchOnMount: true,
      enabled: Boolean(orgSlug),
    },
  });
};
