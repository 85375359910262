// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--n2Phu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient--k4rmf {
  gradient-units: 'userSpaceOnUse';
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/hyper-logo/HyperLogo.m.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.gradient {\n  gradient-units: 'userSpaceOnUse';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container--n2Phu`,
	"gradient": `gradient--k4rmf`
};
export default ___CSS_LOADER_EXPORT___;
