import React, { PropsWithChildren, Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAuthLogout } from '../../../hooks/useAuthLogout';
import { useAuthUser } from '../../../hooks/useAuthUser';
import HyperLogo from '../../common/hyper-logo/HyperLogo';
import LoadingLogo from '../../common/hyper-logo/LoadingLogo';

import styles from './MainLayout.m.css';

const AppHeader = () => {
  const navigate = useNavigate();
  const { user } = useAuthUser();
  const onLogout = useAuthLogout();
  return (
    <header className={styles.header}>
      <div className={[styles.content, styles['nav-primary']].join(' ')}>
        <div className={styles.logo}>
          <HyperLogo variant="wire" onClick={() => navigate('/')} />
          <div>Hyper Portal</div>
        </div>
        <div className={styles.user}>
          {user.given_name}
          <span className={styles.logout} onClick={onLogout}>
            Logout
          </span>
        </div>
      </div>
    </header>
  );
};

function AppFooter() {
  return (
    <footer className={styles.footer}>
      {/* HYPER_VERSION here is injected automatically at build by webpacks */}
      <span>&copy; Hyper AR LTD (version {HYPER_VERSION})</span>
    </footer>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
const MainLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className={styles.container}>
      <AppHeader />

      <main className={styles.content}>
        <Suspense fallback={<LoadingLogo />}>{children ?? <Outlet />}</Suspense>
      </main>

      <AppFooter />
    </div>
  );
};
export default MainLayout;
