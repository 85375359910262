import { Amplify } from 'aws-amplify';
import { useEffect, useState } from 'react';

import { AmplifyConfig } from '@dentreality/hyper-portal-dtos';

export const useAmplifyConfig = () => {
  const [data, setData] = useState<AmplifyConfig | null>(null);
  const [fetching, setFetching] = useState(false);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleFetch = async () => {
    setError(null);
    setFetching(true);

    try {
      const data = await fetch('/api/v1/amplify/config').then((res) =>
        res.json(),
      );
      setData(data);
    } catch (err) {
      setError(err as Error);
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {
    if (data) {
      Amplify.configure({
        Auth: {
          region: data.AWS_REGION,
          userPoolId: data.COGNITO_POOL_ID,
          userPoolWebClientId: data.COGNITO_APP_CLIENT_ID,
        },
      });
      setReady(true);
    }
  }, [data]);

  return { data, fetching, error, ready, fetch: handleFetch };
};
