import React, { CSSProperties, useMemo } from 'react';

import GlassLogo from './GlassLogo';
import GradientLogo from './GradientLogo';
import styles from './HyperLogo.m.css';
import SolidLogo from './SolidLogo';
import WireframeLogo from './WireframeLogo';

type HyperLogoProps = {
  style?: CSSProperties;
  onClick?: () => void;
} & (
  | {
      variant?: 'glass' | 'gradient';
      color?: undefined;
    }
  | { variant: 'wire' | 'solid'; color?: `#${string}` }
);

const HyperLogo: React.FC<HyperLogoProps> = ({
  style,
  variant,
  color,
  onClick,
}) => {
  const SVG = useMemo(() => {
    switch (variant) {
      case 'solid':
        return <SolidLogo color={color} />;
      case 'glass':
        return <GlassLogo />;
      case 'gradient':
        return <GradientLogo />;
      case 'wire':
      default:
        return <WireframeLogo color={color} />;
    }
  }, [variant, color]);

  return (
    <div
      className={styles.container}
      style={{ width: 32, height: 32, ...style }}
      onClick={onClick}
    >
      {SVG}
    </div>
  );
};

export default HyperLogo;
