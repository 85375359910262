import { useAuthenticator } from '@aws-amplify/ui-react';

import { PermissionsObject } from '@dentreality/hyper-permissions';

import { useHasPermission } from '../context/permissions';

export type User = {
  id: string;
  given_name: string;
  family_name: string;
  email: string;
  permissions: PermissionsObject;
};

export function useAuthUser(): {
  user: User;
} {
  const auth = useAuthenticator((context) => [context.user]);
  const { permissions } = useHasPermission();
  return {
    user: {
      id: auth.user.username || '',
      given_name: auth.user.attributes?.given_name || '',
      family_name: auth.user.attributes?.family_name || '',
      email: auth.user.attributes?.email || '',
      permissions,
    } as User,
  };
}
