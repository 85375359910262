import { Auth } from 'aws-amplify';
import { useCallback } from 'react';

export interface AuthToken {
  getToken: () => Promise<string>;
}

async function getToken() {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}

export function useAuthToken(): AuthToken {
  const _getToken = useCallback(async () => {
    return getToken();
  }, []);
  return {
    getToken: _getToken,
  };
}

export function useAuthToken2() {
  return getToken;
}
