// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoContainer--Ks1gl {
    display: flex;
    justify-content: center;
    padding: 30px 0;
}

.logo--xFJrn {
    width: calc(var(--grid-size) * 12);
}

.title--vNIVj {
    margin: 0 32px;
    color: var(--primary-one);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/navigation/AppNavigation.m.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":["@import '../variables.css';\n\n.logoContainer {\n    display: flex;\n    justify-content: center;\n    padding: 30px 0;\n}\n\n.logo {\n    width: calc(var(--grid-size) * 12);\n}\n\n.title {\n    margin: 0 32px;\n    color: var(--primary-one);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": `logoContainer--Ks1gl`,
	"logo": `logo--xFJrn`,
	"title": `title--vNIVj`
};
export default ___CSS_LOADER_EXPORT___;
