import { createTheme } from '@mantine/core';

const mantineTheme = createTheme({
  components: {
    Alert: {
      styles: {
        message: {
          marginTop: 0,
        },
      },
    },
    Dropzone: {
      styles: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'var(--mantine-color-text)',
          padding: 'var(--mantine-spacing-md)',
          border: 'calc(0.0625rem * var(--mantine-scale)) dashed',
          borderRadius: 'var(--mantine-radius-default)',
          borderColor: 'var(--mantine-color-gray-4)',
          cursor: 'pointer',
          marginTop: 0,
        },
      },
    },
    Modal: {
      defaultProps: {
        centered: true,
        closeOnEsc: false,
        closeOnClickOutside: false,
        withCloseButton: false,
      },
      styles: {
        title: {
          fontSize: '1.5em',
          fontWeight: 'bold',
        },
      },
    },
    TextInput: {
      styles: {
        input: {
          fontSize: '12px',
          fontFamily: 'var(--font-family-distinct-chars)',
        },
        error: {
          fontSize: '12px',
          fontFamily: 'var(--font-family-distinct-chars)',
        },
      },
    },
    NumberInput: {
      styles: {
        input: {
          fontFamily: 'var(--font-family-distinct-chars)',
        },
      },
    },
    Paper: {
      styles: { root: { padding: 'var(--mantine-spacing-xs)' } },
    },
    Tooltip: {
      styles: {
        tooltip: {
          fontSize: 'var(--mantine-font-size-xs)',
        },
      },
    },
    Select: {
      defaultProps: {
        checkIconPosition: 'right',
      },
    },
    Kbd: {
      styles: {
        root: {
          userSelect: 'none',
        },
      },
    },
  },
});

export default mantineTheme;
