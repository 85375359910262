import { Button } from '@mantine/core';
import * as React from 'react';

import { Dialog } from '../dialog/Dialog';

export interface ConfirmationDialogMessages {
  title?: string;
  message: string | JSX.Element | undefined;
  confirmMessage?: string;
  cancelMessage?: string;
}

export const ConfirmationDialog: React.FC<{
  messages: ConfirmationDialogMessages;
  onConfirm: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  cancelDisabled?: boolean;
  fixedButtonWidth?: boolean;
  large?: boolean;
}> = (props) => {
  const {
    messages,
    onConfirm,
    onCancel,
    disabled = false,
    fixedButtonWidth = true,
    cancelDisabled = false,
    large,
  } = props;
  const {
    title,
    message,
    confirmMessage = 'Confirm',
    cancelMessage = 'Cancel',
  } = messages;

  return message ? (
    <Dialog
      title={title}
      message={message}
      large={large}
      buttons={
        onCancel
          ? [
              <Button
                key="cancel"
                name={'cancel'}
                color="red"
                onClick={onCancel}
                disabled={cancelDisabled}
                fullWidth
              >
                {cancelMessage}
              </Button>,
              <Button
                key="confirm"
                name={'confirm'}
                disabled={disabled}
                onClick={onConfirm}
                fullWidth
              >
                {confirmMessage}
              </Button>,
            ]
          : [
              <Button
                key="confirm"
                name={'confirm'}
                disabled={disabled}
                onClick={onConfirm}
                fullWidth
              >
                {confirmMessage}
              </Button>,
            ]
      }
      fixedButtonWidth={fixedButtonWidth}
    />
  ) : null;
};
