import '@aws-amplify/ui-react/styles.css';
import '@mantine/core/styles.css';
import 'mantine-datatable/styles.layer.css';

import { Theme, ThemeProvider, useTheme } from '@aws-amplify/ui-react';
import { MantineProvider } from '@mantine/core';
import { NavigationProgress } from '@mantine/nprogress';
import React from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

import { useAmplifyConfig } from '../../hooks/useAmplifyConfig';
import LoadingLogo from '../common/hyper-logo/LoadingLogo';

import App from './App';
import styles from './App.m.css';
import mantineTheme from './theme';

export default function Root() {
  const { tokens } = useTheme();
  const { ready, error } = useAmplifyConfig();

  const theme: Theme = {
    name: 'Hyper Theme',
    tokens: {
      colors: {
        background: {
          primary: {
            value: tokens.colors.white.value,
          },
          secondary: {
            value: tokens.colors.neutral['100'].value,
          },
        },
      },
      components: {
        card: { borderRadius: tokens.radii.medium },
        fieldcontrol: {
          borderRadius: { value: tokens.radii.medium.value },
          fontSize: { value: tokens.fontSizes.xs.value },
        },
        button: {
          borderRadius: { value: tokens.radii.medium.value },
          primary: {
            backgroundColor: { value: '#3083e7' },
            _hover: {
              backgroundColor: { value: '#5f9fec' },
            },
          },
        },
        authenticator: {
          router: {
            boxShadow: tokens.shadows.large,
            borderWidth: { value: '0' },
          },
          container: {
            widthMax: { value: '300px' },
          },
          modal: {
            backgroundColor: { value: '#3083e7' },
          },
        },
      },
    },
  };

  if (error) return <div>There was a problem initialising the app.</div>;
  if (!ready) return <LoadingLogo fullScreen />;

  return (
    <ThemeProvider theme={theme}>
      <MantineProvider theme={mantineTheme}>
        <NavigationProgress zIndex={2000} className={styles.navProgress} />
        <App />
      </MantineProvider>
      <Zendesk
        defer
        zendeskKey={'5ca95bf9-db35-47df-9927-d4e4eb05fd90'}
        zendeskSettings={{}}
        onLoaded={() => {
          ZendeskAPI('webWidget', 'hide');
          ZendeskAPI('webWidget:on', 'close', () =>
            ZendeskAPI('webWidget', 'hide'),
          );
        }}
      />
    </ThemeProvider>
  );
}
